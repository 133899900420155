export const TOP_NAV_MOBILE_HEIGHT = "120px";
export const TOP_NAV_HEIGHT = "110px";
export const CTA_TEXT_BANNER_HEIGHT = "100px";
export const HEADING_FONT_FAMILY = "OpenSans-Bold, Arial, sans-serif";

//-----------TEXT-----------//
export const HERO_DESCRIPTION_TEXT =
  "Plus de Thrillers, de Science-Fiction, de Glamour et de Fun avec Universal+ !";
export const CTA_BUTTON_TEXT = "ESSAI GRATUIT";
export const CTA_BANNER_BUTTON_TEXT = "Essai gratuit";
export const CTA_BANNER_TEXT = `
Sur Universal+, accédez à plusieurs milliers d'heures de séries à la
demande ainsi qu’aux chaînes 13ème\u00A0RUE, SYFY, E! et DreamWorks !
`;
export const CTA_BANNER_MOBILE_TEXT =
  "Accédez à des milliers d'heures de séries à la demande sur Universal+.";
export const CTA_LEGAL_TEXT =
  "Pour les abonnés Prime Video. Abonnement requis, voir conditions.";

export const CTA_BUTTON_LINK =
  "https://www.primevideo.com/offers?benefitId=universalplusfr";
export const FOOTER_LINK_TITLE = " Liens utiles";
export const HEADER_TEXT = "Regarder Universal+ sur :";
export const UNIVERSAL_TEXT = {
  line1: "Faites le plein de nouvelles séries sur Universal+ !",
  line2:
    "Que vous soyez fans de séries policières US, de productions originales françaises, de séries Science-Fiction et Fantastique, de télé-réalité US ou encore de dessins animés pour toute la famille, sur Universal+, il y en a pour tous les goûts !",
};
export const AFFILIATE_GROUP_1_TITLE = `Universal+ à la carte, 
sans engagement
`;
export const AFFILIATE_GROUP_2_TITLE = `Universal+ à la carte 
et inclus dans des bouquets
`;
export const AFFILIATE_GROUP_3_TITLE = `Universal+ inclus dans des bouquets
`;
export const AFFILIATE_AMAZON_TEXT = `Universal+ est accessible dans l’onglet Abonnement
5.99€/mois, sans engagement
Essai Gratuit 7 jours
`;
export const AFFILIATE_BOUYGUES_TEXT = `Universal+ est inclus dans les bouquets TV Bbox divertissement, TV Bbox famille, TV Bbox Ciné, TV Bbox Grand Ciné et TV Bbox jeunesse 

Option à la carte 5,99€/mois
`;
export const AFFILIATE_FREE_TEXT = `Universal+ est inclus avec Freebox Ultra, Delta et Pop avec TV by Canal

En option à 5,99€/mois avec les autres Freebox (hors Crystal/v5)
`;
export const AFFILIATE_SFR_TEXT = `Universal+ est inclus dans le bouquet Famille

Inclus dans le bouquet Famille + Netflix et Disney+ Standard et Bouquet Famille
`;
export const AFFILIATE_MOLOTOV_TEXT = `Universal+ est inclus dans l’offre Grand Cinéma et Extended
`;

//-----------IDS-----------//
export const CTA_BUTTON_ID = "cta-button";
export const BANNER_BUTTON_ID = "banner-button";
export const WHERE_TO_WATCH_BUTTON_ID = "where-to-watch-button";
export const AFFILIATE_PRIMEVIDEO_BUTTON = "affiliate-primevideo-button";
export const AFFILIATE_SFR_BUTTON = "affiliate-sfr-button";
export const AFFILIATE_BOUYGUES_BUTTON = "affiliate-bouygues-button";
export const AFFILIATE_MOLOTOV_BUTTON = "affiliate-molotov-button";
export const AFFILIATE_FREE_BUTTON = "affiliate-free-button";
export const AFFILIATE_ORANGE_BUTTON = "affiliate-orange-button";
export const SOCIALMEDIA_FACEBOOK_BUTTON = "socialmedia-facebook-button";
export const SOCIALMEDIA_YOUTUBE_BUTTON = "socialmedia-youtube-button";
export const SOCIALMEDIA_INSTAGRAM_BUTTON = "socialmedia-instagram-button";
export const SOCIALMEDIA_TIKTOK_BUTTON = "socialmedia-tiktok-button";
export const MOSAIC_SECTION_ID = "mosaic-section";
export const THIRTEENTH_STREET_SECTION = "13th-street-section";
export const SYFY_SECTION = "syfy-section";
export const E_SECTION = "e-section";
export const DREAMWORKS_SECTION = "dreamworks-section";
export const ORIGINAL_PRODUCTIONS_SECTION_ID = "original-productions-section";
export const SHOWCASE_SECTION = "showcase-section";
export const AFFILIATE_SECTION = "affiliate-section";
export const SOCIAL_SECTION = "social-section";
export const MONTHLY_SECTION_ID = "monthly-section";
export const FAQ_SECTION = "faq-section";
export const FOOTER_SECTION = "footer-section";
export const MOSAIC_VIDEO_BUTTON = "mosaic-video-button";
export const SHOWCASE_VIDEO_BUTTON = "showcase-video-button";
export const FAQ_QUESTION_1 = "faq-question-1";
export const FAQ_QUESTION_2 = "faq-question-2";
export const FAQ_QUESTION_3 = "faq-question-3";
export const FAQ_QUESTION_4 = "faq-question-4";
export const FAQ_QUESTION_5 = "faq-question-5";
export const FAQ_QUESTION_6 = "faq-question-6";
export const FAQ_QUESTION_7 = "faq-question-7";
export const SCROLL_13EME_BUTTON = "scroll-13eme-channel-button";
export const SCROLL_SYFY_BUTTON = "scroll-syfy-channel-button";
export const SCROLL_E_BUTTON = "scroll-e-channel-button";
export const SCROLL_DREAMWORKS_BUTTON = "scroll-dreamworks-channel-button";
export const SCROLL_MONTHLY_BUTTON = "scroll-monthly-button";
